import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import EventPlot from '../../components/EventPlot'
import Checkup from '../../components/Checkup'
import Footer from '../../components/Footer'
import beyondbluePDF from './beyondblue_antidepressants.pdf'
import './Home.css'

export default class Home extends Component {
  state = {
    isInfoActive: false,
  }
  handleInfoClick = () => {
    this.setState({isInfoActive: !this.state.isInfoActive})
  }
  handleCloseInfoClick = () => {
    this.setState({isInfoActive: false})
  }
  handleRedCapClick = () => {
    window.open(this.props.user.redcaplink, '_blank').focus()
  }
  handleRedCapConfirm = () => {
    this.props.onHomeStep('Plan')
  }

  renderPrescription = () => {
    const now = new Date()
    const p = this.props.prescriptions.reduce((prev, {date, note}) => (
      new Date(date) < now ? note : prev
    ), this.props.prescriptions[0].note)
    const date = now.toLocaleString('en-us', {day: 'numeric', month: 'short', year: 'numeric'}).split(' ')
    return (
      <div className='prescriptionCont'>
        <div className='today'>
          <div className='todayText'>
            <div className='rotated'>
              {date[1].slice(0,-1) + ' ' + date[0] + ' ' + date[2]}
            </div>
          </div>
        </div>
        <div className='prescription'>
          {p}
          <svg onClick={this.handleInfoClick} className='infoIcon' width="20px" height="20px" viewBox="0 0 20 20">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="outline-info-24px" transform="translate(-2.000000, -2.000000)" fill="#007dcc" fillRule="nonzero">
                <path d="M11,7 L13,7 L13,9 L11,9 L11,7 Z M11,11 L13,11 L13,17 L11,17 L11,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,21 C7.03875,21 3,16.96125 3,12 C3,7.03875 7.03875,3 12,3 C16.96125,3 21,7.03875 21,12 C21,16.96125 16.96125,21 12,21 Z" id="Shape"></path>
              </g>
            </g>
          </svg>
        </div>
        <div className='activities'>
          {
            this.props.activities.map(({id, text}, i) => (
              <div className='activity' key={id}>
                {text}
                { this.props.todayActivities.map((tid) => tid === id ? <svg key={tid} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg> : null) }
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  render () {
    return (
      <div>
        <div className='Home'>
          {
            this.props.schedule === null || this.props.homestep !== "Timeline" ? (
              <div className='noSchedule'>
                <div className='topQuote'>
                  <div className='portraitCont'>
                    <div className='portrait'><img className='portraitImg' src={require('./jane-gunn.jpg')} alt='Professor Jane Gunn'/></div>
                    <div className='caption'>Professor Jane Gunn</div>
                  </div>
                  <div className='quote'>
                    Thank you for participating in the WiserAD trial. Your next steps are explained below.
                  </div>
                </div>
                <div className='steps'>
                  <div className='stepRow'>
                    <div className='stepLeft'>1. Confirm Eligibility - 15 minutes</div>
                    <div className='stepRight'>
                      {
                        this.props.user.surveydone || (this.props.adminActing && this.props.user.actingdone) ? (
                          <div>
                            <Link className="planLink planLinkSmall" to="/survey">Re-try &gt;</Link>
                            <img src={require('./tick.svg')} alt='tick' />  Completed
                          </div>
                        ) : (
                          <Link className="planLink" to="/survey">Go &gt;</Link>
                        )
                      }
                    </div>
                    We need to ensure you are eligible to participate in the WiserAD trial. This will take approx. 10 minutes.
                  </div>
                  {
                    this.props.adminActing && this.props.user.actingdone ? (
                      <div className='stepRow'>
                        <div className='stepLeft'>Return in 2 weeks</div>
                        <div className='stepRight'></div>
                        Thank you, you have completed the first eligibility stage. Please come back in two weeks to confirm your eligibility. 
                      </div>
                    ) : (
                      <div>
                      {
                        this.props.user.surveydone && !this.props.user.surveyineligible ? (
                          <div className='nextSteps'>
                            <div className='stepRow'>
                              <div className='stepLeft'>2. Consent to Participate - 5 minutes</div>
                              <div className='stepRight'>
                                {
                                  this.props.user.consentAgree === true ? (
                                    <div><img src={require('./tick.svg')} alt='tick' />  Completed</div>
                                  ) : (
                                    <Link className='planLink' to="/consent">Go &gt;</Link>
                                  )
                                }
                              </div>
                              If you are eligible to continue using WiserAD we need your online consent to participate in the research trial. This will take approx. 5 minutes.
                            </div>
                            {
                              this.props.user.consentAgree === true ? (
                                <div className='stepRow'>
                                  <div className='stepLeft'>3. Questionnaire - 30 minutes</div>
                                  <div className='stepRight'>
                                    {
                                      this.props.homestep === 'Plan' || this.props.homestep === 'Timeline' ? (
                                        <div>
                                          <span onClick={this.handleRedCapClick} className='redcapLink'>Return &gt;</span>
                                          <div className="tickComplete"><img src={require('./tick.svg')} alt='tick' />  Completed</div>
                                        </div>
                                      ) : (
                                        <span onClick={this.handleRedCapClick} className='planLink'>Go &gt;</span>
                                      )
                                    }
                                  </div>
                                  <div>We would like to learn more about your background, lifestyle and health. This will take approx. 20 minutes.</div>
                                  {
                                    this.props.homestep !== 'Plan' && this.props.homestep !== 'Timeline' ? (
                                      <div className='stepConfirm'>
                                        I have completed the Questionnaire.
                                        <span onClick={this.handleRedCapConfirm} className='planConfirm'>I Confirm</span>
                                      </div>
                                    ) : null
                                  }
                                </div>
                              ) : null
                            }
                            {
                              this.props.homestep === 'Plan' ? (
                                  <div className='stepRow'>
                                      { 
                                        this.props.user.randomise === false ? (
                                          <div>
                                            <div className='stepLeft'>4. Thank you for participating in WiserAD.</div>
                                            <p>We will contact you in 3 months to see how you're getting on and ask you to complete your first follow-up questionnaire.</p>
                                            <p>Please use the following link to read more about antidepressant medication. <a href={beyondbluePDF} target="_blank" rel="noopener noreferrer">Beyond Blue - Antidepressant medication</a></p>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className='stepLeft'>4. Plan - 20 minutes</div>
                                            <div className='stepRight'><Link className='planLink' to="/plan">Go &gt;</Link></div>
                                              <p>Great! Now let's get started.</p>
                                              <p>First we’ll check your details are up to date and then select a medication schedule to reduce your medication.</p>
                                              <p>Finally we’ll  set up a personal well-being plan that will support you through the journey of decreasing or stopping your antidepressant medication in a safe way.</p>
                                              <p>Setting up your well-being plan includes entering details about your current medications, your mood, quality of sleep and daily activities. All of these things are important aspects of your mental and physical health and well-being and checking on them daily can help you see how you’re tracking.</p>
                                              <p>Once you have completed your personal well-being plan, we will ask you to make an appointment with your GP to discuss the new prescription you require.</p>
                                          </div>
                                        )
                                      }
                                  </div>
                              ) : null
                            }
                          </div>
                        ) : null
                      }
                      </div>
                    )
                  }
                  {
                    (this.props.user.surveydone && this.props.user.surveyineligible) || (this.props.adminActing && this.props.user.actingineligible)  ? (
                      <div className='stepRow'>
                        <div className='stepLeft'>Thank you for your time.</div>
                        <div className='stepRight'></div>
                        <p>Now is not the best time to continue using WiserAD. You may like to talk to your GP about other options relating to your antidepressants.</p>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <Checkup symptoms={this.props.symptoms} activities={this.props.activities} onRecordSubmit={this.props.onRecordSubmit}/>
                  <EventPlot prescriptions={this.props.prescriptions} records={this.props.records}/>
                  {
                    this.renderPrescription()
                  }
                </div>
                <div className={'info '+(this.state.isInfoActive ? 'active' : '')}>
                  {
                    this.props.schedules.map((s) => ( 
                      s.id === this.props.schedule ? (
                        <div key={s.id}>
                          <div className='infoContent'>
                            <h4>{ s.text }</h4>
                            <div dangerouslySetInnerHTML={{ __html: s.description}}/>
                            <h4>Schedule</h4>
                            <ul>{ this.props.prescriptions.map((p, i, {length}) => (<li key={p.id}>{i === 0 ? 'Current dose ' : i !== length-1 ? 'Reduce to ' : ''}{p.note} {i !== length-1 ? 'for '+p.days+' days' : '' }{ this.props.timesperday === 2 && (this.props.schedule === 17 || this.props.schedule === 18) ? ( p.dose === 300 ? ' (150mg twice a day)' : p.dose === 225 ? ' (150mg once a day and 75mg once a day)' : p.dose === 150 ? ' (75mg twice a day)' : p.dose === 75 ? ' (75mg once a day)' : p.dose === 37 ? ' (37.5mg once a day)' : '') : '' } {i === 0 ? <br /> : null}{i === 0 ? 'This gives you time to consult with your GP to confirm the prescription. Please let the research team know when you are ready to start.' : null}</li>))}</ul>
                            {
                                this.state.timesperday === 2 ? (
                                  <div>TWICE</div>
                                ) : null
                            }
                          </div>
                        </div>
                      ) : null
                    ))
                  }
                  <svg className='closeInfo' onClick={this.handleCloseInfoClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                </div>
              </div>
            )
          }
        </div>
        <Footer/>
      </div>
    )
  }
}

